<template>
    <b-container fluid class="b-container-no-padding">
        <CommonTabsComponent
            :tabList="tabListPermit"
            @tabChanged="tabChanged"
        ></CommonTabsComponent>
        <component v-bind:is="componentName" v-bind="currentProperties" />
    </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { commonService } from '../services/common.service';
export default {
    components: {
        CommonTabsComponent: () =>
            import('../components/CommonComponents/CommonTabsComponent.vue'),
        DocumentUpload: () => import('./DocumentUpload.vue'),
        FileManager: () => import('./CommonComponents/FileManager.vue'),
        AutotableUpload: () => import('./FileManagement/AutotablesUpload.vue'),
        DirectoryUpload: () => import('./projects/DirectoryUpload.vue'),
        DirectoryClone: () => import('./FileManagement/DirectoryClone.vue'),
    },
    data() {
        return {
            selectedTabName: 'File management',
            tabList: [
                { name: 'File management', component: 'file-manager' },
                { name: 'Upload document', component: 'document-upload' },
                { name: 'Upload directory', component: 'directory-upload' },
                { name: 'Clone directory', component: 'directory-clone' },
                { name: 'Auto tables', component: 'autotable-upload' },
            ],
            tabListPermit: [],
        };
    },
    methods: {
        tabChanged(newTab, prevTab) {
            this.selectedTabName = newTab.name;
        },
        getTools() {
            this.tabListPermit = [];
            commonService.getTools(this.ProjectID, 0).then((res) => {
                res.data.forEach((tool) => {
                    switch (tool.ToolName) {
                        case 'File manager':
                            this.tabListPermit.push({
                                name: 'File management',
                            });
                            break;
                        case 'Document upload':
                            this.tabListPermit.push({
                                name: 'Upload document',
                            });
                            break;
                        case 'Directory upload':
                            this.tabListPermit.push({
                                name: 'Upload directory',
                            });
                            break;
                        case 'Auto report':
                            this.tabListPermit.push({ name: 'Auto tables' });
                            break;
                    }
                });
            });
        },
    },
    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
        componentName() {
            return this.tabList.find((tab) => tab.name === this.selectedTabName)
                .component;
        },
        currentProperties() {
            let toReturn = {};
            switch (this.selectedTabName) {
                case 'File management':
                    toReturn = {
                        fileManagerHeight: 'calc(100vh - 126px)',
                    };
                    break;
            }
            return toReturn;
        },
    },
    watch: {
        ProjectID(newProjectID) {
            if (newProjectID !== 0) {
                this.getTools();
            }
        },
    },
    created() {
        this.getTools();
    },
};
</script>

<style scoped>
.b-container-no-padding {
    padding: 0px;
}
</style>
